import { useTranslation } from '@dagens/frontend-i18n';
import { ProducerDeliveryFee } from '../../../types/Producer';
import { OrderProducerDeliveryFee } from '../../../types/Order';
import Typography from '@carrot-deprecated/typography';
import { ClickActionIconContainer } from '_common/components/table/CommonTable.style';
import Icon from '_common/components/utils/Icon';

type ChangedPriceCellProps = {
  orderDeliveryFee: OrderProducerDeliveryFee;
};

const ChangedPriceCell = ({ orderDeliveryFee }: ChangedPriceCellProps) => {
  const price = orderDeliveryFee.finalValue;
  const originalPrice = orderDeliveryFee?.valueAtTimeOfOrder;
  return (
    <>
      <Typography variant="paragraphSmall">{price} kr</Typography>
      {originalPrice !== undefined && price !== originalPrice && (
        <Typography
          variant="paragraphSmall"
          color="secondary"
          style={{ textDecoration: 'line-through' }}
        >
          {originalPrice} kr
        </Typography>
      )}
    </>
  );
};

type DeliveryFeeRowProps = {
  orderDeliveryFee: OrderProducerDeliveryFee;
  originalDeliveryFee?: ProducerDeliveryFee;
  onClick: () => void;
  isReadOnly?: boolean;
};

export const DeliveryFeeRow = ({
  orderDeliveryFee,
  onClick,
  isReadOnly = false
}: DeliveryFeeRowProps) => {
  const { t } = useTranslation();
  return (
    <tr style={!isReadOnly ? { cursor: 'pointer' } : {}} onClick={onClick}>
      <td>
        <Typography variant="paragraphSmall">
          {t('producer:deliveryFee')}
        </Typography>
      </td>
      <td
        style={{
          position: 'relative',
          paddingRight: isReadOnly ? undefined : '30px'
        }}
      >
        <ChangedPriceCell orderDeliveryFee={orderDeliveryFee} />
        {!isReadOnly && (
          <ClickActionIconContainer>
            <Icon icon="edit" $size="m" fill="deepPurple" />
          </ClickActionIconContainer>
        )}
      </td>
    </tr>
  );
};
