import { Button as CarrotButton, Icon } from '@dagens/carrot';
import { capitalize } from 'lodash';
import { useState } from 'react';
import { useTranslation } from '@dagens/frontend-i18n';
import DeliveryTypeTag from '../../../../_common/components/tags/DeliveryTypeTag';
import { DeliveryType } from '../../../../types/Logistics';
import { useMediaQuery } from '../../../../_common/hooks/useMediaQueries';
import { FormField } from '../../../../carrot-deprecated/form-field';
import { FormFieldType } from '../../../../carrot-deprecated/form-field/types';
import { OrderProducerDeliveryFee } from '../../../../types/Order';
import Container from '@carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';
import { formatDate } from 'utils/date/format';
import OrderLineTable from '_common/components/orderline-table/OrderLineTable';

type Props = {
  deliveryDate: string;
  orderLines: any;
  orderDeliveryFee?: OrderProducerDeliveryFee;
  withDagensLogistics: boolean;
  orderReference: string;
  messageFromProducerToConsumer: string;
  handleOrderReferenceChange: (deliveryDate: string, value: string) => void;
  handleMessageFromProducerToConsumerChange: (
    deliveryDate: string,
    value: string
  ) => void;
};

export const OrderDeliveryDay = ({
  deliveryDate,
  orderLines,
  orderDeliveryFee,
  withDagensLogistics,
  orderReference,
  messageFromProducerToConsumer,
  handleOrderReferenceChange,
  handleMessageFromProducerToConsumerChange
}: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { tabletUp, desktopUp } = useMediaQuery();
  return (
    <Container key={deliveryDate} mb="xl">
      <Container
        flex
        mb="s"
        gap="xs"
        justify={tabletUp ? 'flex-end' : 'flex-start'}
        alignItems={tabletUp ? 'center' : 'flex-start'}
        direction={tabletUp ? 'row-reverse' : 'column'}
      >
        <DeliveryTypeTag
          deliveryType={
            withDagensLogistics
              ? DeliveryType.DAGENS_DELIVERY
              : DeliveryType.SELF_DELIVERY
          }
        />
        <Typography as="h2" variant="tertiaryHeading" my="none">
          {capitalize(formatDate(deliveryDate))}
        </Typography>
      </Container>
      <OrderLineTable
        orderId="no-id-yet"
        orderLines={orderLines}
        orderDeliveryFee={orderDeliveryFee}
        isReadOnly
        hideTableHeader
      />
      {!open && (
        <Container mt="s">
          <CarrotButton
            variant="borderless"
            size="small"
            onClick={() => setOpen(true)}
          >
            <Icon icon="orderNote" />
            {t('producer:AddOrderMessageAndReference')}
          </CarrotButton>
        </Container>
      )}
      {open && (
        <Container my="m" flex gap="s">
          <Container flexGrow={1} flexBasis="50%">
            <FormField
              type={FormFieldType.TEXT}
              label={t('producer:MessageToCustomer')}
              helpText={t('producer:WriteMessageToTheCustomer')}
              optional
              inputProps={{
                value: messageFromProducerToConsumer,
                onChange: ({ target: { value } }) => {
                  handleMessageFromProducerToConsumerChange(
                    deliveryDate,
                    value
                  );
                }
              }}
            />
          </Container>
          <Container flexBasis={desktopUp ? undefined : '100%'}>
            <FormField
              type={FormFieldType.TEXT}
              label={t('common:OrderReference')}
              helpText={t('common:OrderReferenceInputHelpText')}
              optional
              inputProps={{
                value: orderReference,
                onChange: ({ target: { value } }) => {
                  handleOrderReferenceChange(deliveryDate, value);
                }
              }}
            />
          </Container>
        </Container>
      )}
    </Container>
  );
};
