export enum FeatureFlags {
  'SELF_DELIVERY_FEE' = 'SELF_DELIVERY_FEE'
}

export const hasUserFeatureFlag = (
  featureFlag: FeatureFlags,
  userFeatureFlags: string[] | undefined
) => {
  return userFeatureFlags && userFeatureFlags.includes(featureFlag);
};
