import { Box, Nav, useMediaQuery, PageHeader } from '@dagens/carrot';
import { useTranslation } from '@dagens/frontend-i18n';
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { SelectedDeliveryDate } from '../../../../_common/components/delivery-date-picker/DeliveryDatePicker';
import Logger from '../../../../services/Logger';
import { OrderLine, OrderProducerDeliveryFee } from '../../../../types/Order';
import { ProductWithUnit } from '../../add-orderline/ProductList';
import { checkDeliveryRouteReady } from './check-delivery-route-ready';
import { trackOrderFlowPlaceOrder } from './track-order-flow';
import { useGenerateOrderLinesFromProducts } from './useGenerateOrderLinesFromProducts';
import { OrderDeliveryDay } from './order-delivery-day';
import Container from '@carrot-deprecated/container';
import Typography from '@carrot-deprecated/typography';
import REQ, { ReqType } from 'utils/REQ';
import { formatDate, slugifyDate } from 'utils/date/format';
import { ProductForSale } from 'types/Product';
import { Consumer } from 'types/Consumer';
import { postOrder, PostOrderResponse } from 'api';
import { Page } from '@components/page';
import { useAppSelector } from '_common/hooks/reduxHooks';
import Button, { ButtonLink } from '_common/components/button/Button.style';

type LocationState =
  | {
      consumer: Consumer;
      deliveryDates: SelectedDeliveryDate[];
      products: ProductWithUnit[];
    }
  | undefined;

const AddOrderCheckoutPage = () => {
  const { desktopUp } = useMediaQuery();
  const location = useLocation();
  const state = location.state as LocationState;
  const selectedConsumer = state?.consumer;
  const selectedDeliveryDates = state?.deliveryDates ?? [];
  const selectedProducts = state?.products ?? [];

  const [submitReq, setSubmitReq] = useState<ReqType>(REQ.INIT);
  const [createdOrderResponse, setCreatedOrderResponse] =
    useState<PostOrderResponse | null>(null);
  const [ordersByDeliveryDays, setOrdersByDeliveryDays] = useState<
    {
      deliveryDate: string;
      withDagensLogistics: boolean;
      orderReference: string;
      messageFromProducerToConsumer: string;
      consumerId?: string;
      consumerName?: string;
      orderLines: OrderLine[];
      orderDeliveryFee?: OrderProducerDeliveryFee;
    }[]
  >([]);
  const { roleId, userId, deliveryFee, products } = useAppSelector(
    ({ auth, producerProducts: { items } }) => {
      return {
        roleId: auth._id,
        userId: auth.uid,
        deliveryFee: auth.selfDeliveryFees?.at(0),
        products: items as unknown as ProductForSale[] // We need to clean up in Product-type, we can't have two who gets used around each other
      };
    }
  );
  const generateOrderLinesFromSelectedProducts =
    useGenerateOrderLinesFromProducts({
      products
    });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const placeOrders = async () => {
    const orderPayloadsByDeliveryDate = ordersByDeliveryDays.map(
      ({
        deliveryDate,
        withDagensLogistics,
        orderReference,
        messageFromProducerToConsumer,
        consumerId,
        orderLines,
        orderDeliveryFee
      }) => {
        return {
          basket: orderLines.map(({ _key, nrOfUnits }) => {
            return {
              productId: _key,
              unitsInBasket: nrOfUnits
            };
          }),
          deliveryDate: slugifyDate(deliveryDate),
          orderDeliveryFee,
          consumerId,
          createdBy: userId,
          createdAs: roleId,
          messageFromProducerToConsumer,
          withLogistics: withDagensLogistics,
          orderReference
        };
      }
    );
    setSubmitReq(REQ.PENDING);
    try {
      Logger.info(
        `Producer with id ${roleId} creating ${ordersByDeliveryDays.length} order(s) for ${selectedConsumer?.name}`
      );
      const createdOrdersResponse = await Promise.all(
        orderPayloadsByDeliveryDate.map(async ordersByDeliveryDate => {
          Logger.debug(
            `Creating order for delivery date ${ordersByDeliveryDate.deliveryDate}`
          );
          const { orderDeliveryFee, ...payload } = ordersByDeliveryDate;
          const postOrderRes = await postOrder({
            ...payload,
            producerDeliveryFeeId: orderDeliveryFee?.ref?._id
          });
          trackOrderFlowPlaceOrder(
            generateOrderLinesFromSelectedProducts(
              selectedProducts,
              selectedConsumer?._id
            ),
            selectedProducts,
            selectedConsumer?._id,
            selectedConsumer?.name,
            products,
            ordersByDeliveryDate.orderDeliveryFee?.finalValue
          );
          return postOrderRes;
        })
      );
      const createdOrdersResponseFlatten = createdOrdersResponse.flat();
      setCreatedOrderResponse(createdOrdersResponseFlatten);
      await Promise.all(
        await createdOrdersResponseFlatten.map(async ({ orderId }) => {
          await checkDeliveryRouteReady(orderId, 0);
        })
      );
      setSubmitReq(REQ.SUCCESS);
    } catch (error) {
      setSubmitReq(REQ.ERROR);
    }
  };

  const handleMessageFromProducerToConsumerChange = (
    deliveryDate: string,
    message: string
  ) => {
    const updatedOrdersByDeliveryDays = ordersByDeliveryDays.map(
      orderByDeliveryDay => {
        if (orderByDeliveryDay.deliveryDate === deliveryDate) {
          return {
            ...orderByDeliveryDay,
            messageFromProducerToConsumer: message
          };
        }
        return orderByDeliveryDay;
      }
    );
    setOrdersByDeliveryDays(updatedOrdersByDeliveryDays);
  };

  const handleOrderReferenceChange = (
    deliveryDate: string,
    reference: string
  ) => {
    const updatedOrdersByDeliveryDays = ordersByDeliveryDays.map(
      orderByDeliveryDay => {
        if (orderByDeliveryDay.deliveryDate === deliveryDate) {
          return {
            ...orderByDeliveryDay,
            orderReference: reference
          };
        }
        return orderByDeliveryDay;
      }
    );
    setOrdersByDeliveryDays(updatedOrdersByDeliveryDays);
  };

  useEffect(() => {
    const ordersByDeliveryDaysGenerated = selectedDeliveryDates.map(
      selectedDeliveryDate => {
        return {
          deliveryDate: slugifyDate(selectedDeliveryDate.deliveryDate),
          withDagensLogistics: selectedDeliveryDate.withLogistics,
          orderReference: '',
          messageFromProducerToConsumer: '',
          consumerId: selectedConsumer?._id,
          consumerName: selectedConsumer?.name,
          orderLines: generateOrderLinesFromSelectedProducts(
            selectedProducts,
            selectedConsumer?._id
          ),
          orderDeliveryFee: selectedDeliveryDate.withLogistics
            ? undefined
            : {
                ref: deliveryFee,
                valueAtTimeOfOrder: deliveryFee?.price,
                finalValue: deliveryFee?.price
              }
        };
      }
    );
    setOrdersByDeliveryDays(ordersByDeliveryDaysGenerated);
  }, []);

  if (
    !selectedConsumer ||
    selectedDeliveryDates.length === 0 ||
    !selectedProducts
  ) {
    return <Navigate to="/orders/add/start" />;
  }

  if (submitReq === REQ.SUCCESS) {
    setTimeout(() => {
      if (createdOrderResponse?.length === 1) {
        navigate(`/orders/${createdOrderResponse[0].secret}`);
      } else {
        navigate('/orders');
      }
    }, 3000);
  }

  const title = t('producer:ConfirmTheOrder');
  const subtitle = `${t('common:MakeNewOrder')} (4/4)`;
  const context = `${t('common:to')} ${selectedConsumer.name}, ${
    selectedDeliveryDates.length > 1
      ? `${ordersByDeliveryDays.length} ${t('common:order', {
          count: ordersByDeliveryDays.length
        })}`
      : formatDate(selectedDeliveryDates[0].deliveryDate)
  }`;

  return (
    <Page
      navMobileText={
        <>
          <Nav.ContextTitle>{title}</Nav.ContextTitle>
          <Nav.ContextSubtitle>{subtitle}</Nav.ContextSubtitle>
        </>
      }
      header={
        <Box.FullWidth>
          {desktopUp && <PageHeader title={title} subtitle={subtitle} />}
          <Typography my="m">{context}</Typography>
        </Box.FullWidth>
      }
      bottom={
        <Box.BottomSheet border>
          {(submitReq === REQ.INIT || submitReq === REQ.ERROR) && (
            <>
              <ButtonLink
                to="/orders/add/products"
                state={{
                  consumer: selectedConsumer,
                  deliveryDates: selectedDeliveryDates,
                  products: selectedProducts
                }}
              >
                {t('common:BackWithArrow')}
              </ButtonLink>
              <Button
                onClick={() => {
                  placeOrders();
                }}
                variant="primary"
              >
                {t('producer:PlaceTheOrder', {
                  count: ordersByDeliveryDays.length
                })}
              </Button>
            </>
          )}
          {submitReq === REQ.PENDING && (
            <Typography variant="paragraph" color="secondary">
              {t('producer:PlacingTheOrder', {
                count: ordersByDeliveryDays.length
              })}
            </Typography>
          )}
          {submitReq === REQ.SUCCESS && (
            <Typography variant="paragraph" color="secondary">
              {t('producer:CreateNewOrderOrderCreatedFeedback', {
                count: ordersByDeliveryDays.length
              })}
            </Typography>
          )}
        </Box.BottomSheet>
      }
    >
      <Container mt="none" mb="xl">
        <Typography variant="paragraphSmall">
          {t('producer:CreateNewOrderCalloutText', {
            count: ordersByDeliveryDays.length
          })}
        </Typography>
      </Container>
      {ordersByDeliveryDays.map(
        ({
          deliveryDate,
          withDagensLogistics,
          orderLines,
          orderDeliveryFee,
          messageFromProducerToConsumer,
          orderReference
        }) => {
          return (
            <OrderDeliveryDay
              key={deliveryDate}
              deliveryDate={deliveryDate}
              withDagensLogistics={withDagensLogistics}
              orderLines={orderLines}
              orderDeliveryFee={orderDeliveryFee}
              messageFromProducerToConsumer={messageFromProducerToConsumer}
              orderReference={orderReference}
              handleMessageFromProducerToConsumerChange={
                handleMessageFromProducerToConsumerChange
              }
              handleOrderReferenceChange={handleOrderReferenceChange}
            />
          );
        }
      )}
    </Page>
  );
};

export default AddOrderCheckoutPage;
